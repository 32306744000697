import { CheckIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';

import {
  headingSizeTheme as HEADING_SIZE,
  bodyCopySizeTheme as BODY_COPY_SIZE,
  getThemeStyles,
} from 'holded/lib/styles';
import { getCustomTag, isDefault } from 'holded/lib/utils';
import { Feature6Section } from 'holded/modules/cms/domain/components/feature';
import { defaultThemeValues, FeatureTheme } from 'holded/modules/cms/ui/sections/feature/FeatureTheme';

const Feature6 = ({ id, title, titleSecondary, description, features, background, customTag }: Feature6Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  const CustomTag = getCustomTag('h4', customTag?.headingTag);
  const defaultHeadingSize = isDefault(customTag?.headingSize);
  const defaultBodySize = isDefault(customTag?.bodyCopySize);
  const headingSize = `text-h4 md:${HEADING_SIZE[customTag?.headingSize ?? 'default']}`;

  useEffect(() => {
    const themeStyles = getThemeStyles(FeatureTheme.feature, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <>
      {id && (
        <div className={`${themeValues.background}`}>
          <div className={`p-10 md:py-16 md:px-4 sm:px-6 max-w-7xl mx-auto md:grid md:grid-cols-3 md:gap-x-8`}>
            <div>
              {titleSecondary && (
                <p className={`${themeValues.titleSecondaryColor} uppercase text-b3 font-semibold tracking-wide mb-2`}>
                  {titleSecondary}
                </p>
              )}
              {title && (
                <CustomTag
                  className={`${defaultHeadingSize ? 'text-h4' : headingSize} font-extrabold ${themeValues.titleColor}`}
                >
                  {title}
                </CustomTag>
              )}
              {description && (
                <p
                  className={`mt-4 ${
                    defaultBodySize ? 'text-b2' : BODY_COPY_SIZE[customTag?.bodyCopySize ?? 'default']
                  } ${themeValues.descriptionColor} leading-6`}
                >
                  {description}
                </p>
              )}
            </div>
            {features && (
              <div className="mt-12 lg:mt-0 md:col-span-2 md:ml-4">
                <dl className={`lg:grid lg:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8`}>
                  {features?.map((feature) => (
                    <div key={feature.id} className="relative pb-4 lg:pb-0">
                      {feature.title && (
                        <dt>
                          <CheckIcon className={`absolute h-6 w-6 ${themeValues.subtitleColor}`} aria-hidden="true" />
                          <p className={`ml-9 text-h5 leading-6 font-medium ${themeValues.titleColor}`}>
                            {feature.title}
                          </p>
                        </dt>
                      )}
                      {feature.description && (
                        <dd className={`mt-2 ml-9 text-base ${themeValues.descriptionColor}`}>{feature.description}</dd>
                      )}
                    </div>
                  ))}
                </dl>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Feature6;
